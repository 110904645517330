import React, { useState, useEffect }  from 'react'

// import img from '../images/img2.jpg'
import img2 from '../images/img1.jpg'
import img3 from '../images/img6.jpg'
import img4 from '../images/kivo1.webp'


export default function Banner() {

  const images = [
   
    img3,
    // img,
    img2,
    img4,
   
  ];

const [currentIndex, setCurrentIndex] = useState(0);

useEffect(() => {
const interval = setInterval(() => {
setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
}, 5000); // Change every 5 seconds
return () => clearInterval(interval); // Cleanup interval on component unmount
}, [images.length]);

  return (
    <div className=''>
      <div className='text-slate-900 shadow ' id='nav-bar' style={{backgroundImage: `url(${images[currentIndex]})` }}> 
      </div>   
    </div>
  )
}
